gsap.registerPlugin(ScrollTrigger);

class Farbwechsler {
    constructor({ trigger, text, containerText, slider, buttons }) {
        this.trigger = trigger ? document.querySelector(trigger) : null;
        this.text = text ? document.querySelector(text) : null;
        this.containerText = containerText ? document.querySelector(containerText) : null;
        this.slider = slider ? document.querySelector(slider) : null;
        this.buttons = buttons ? document.querySelectorAll(buttons) : null;
        this.sliderWidth = 0;

        if (this.slider) {
            this.sliderWidth = this.slider.clientWidth - window.innerWidth;
        }

        if (this.text || this.containerText) {
            this.textTop = this.text.getBoundingClientRect().top;
            this.containerTextHeight = this.containerText.getBoundingClientRect().height;
        }

        window.addEventListener('resize', debounce(this.refresh.bind(this), 100));
    }

    refresh() {
        if (this.slider) {
            this.sliderWidth = this.slider.clientWidth - window.innerWidth;
        }

        if (this.text || this.containerText) {
            this.textTop = this.text.getBoundingClientRect().top;
            this.containerTextHeight = this.containerText.getBoundingClientRect().height;

            console.log("textTop: " + this.textTop + " containerTextHeight: " + this.containerTextHeight + " sliderWidth:  " + this.sliderWidth);
            console.log(((this.textTop + this.containerTextHeight) - this.sliderWidth) * -1);
        }
        ScrollTrigger.refresh();
    }

    farbeWechselnTextMitContainer() {
        if (!this.trigger || !this.text || !this.containerText) return;

        if (this.slider) {
            gsap.timeline({
                scrollTrigger: {
                    trigger: this.trigger,
                    start: () => `${((this.textTop + this.containerTextHeight) - this.sliderWidth) * -1} top`,
                    end: () => `${(this.textTop - this.sliderWidth) * -1} top`,
                    scrub: true,
                    invalidateOnRefresh: true,
                }
            }).to(this.containerText, {
                immediateRender: true,
                ease: 'none',
                backgroundPositionY: '100%'
            }, 0);
        } else {
            gsap.timeline({
                scrollTrigger: {
                    trigger: this.trigger,
                    start: () => `${((this.textTop + this.containerTextHeight)) * -1} top`,
                    end: () => `${(this.textTop) * -1} top`,
                    scrub: true,
                    invalidateOnRefresh: true,
                }
            }).to(this.containerText, {
                immediateRender: true,
                ease: 'none',
                backgroundPositionY: '100%'
            }, 0);
        }
    }

    farbeWechselnText() {
        if (!this.trigger || !this.buttons) return;

        if (this.slider) {
            this.sliderWidth = this.slider.clientWidth - window.innerWidth;

            this.buttons.forEach((button) => {
                gsap.to(button, {
                    scrollTrigger: {
                        trigger: this.trigger,
                        start: () => `${(button.getBoundingClientRect().top + button.getBoundingClientRect().height - this.sliderWidth) * -1} top`,
                        end: () => `${(button.getBoundingClientRect().top - this.sliderWidth) * -1} top`,
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                    backgroundPositionY: '100%',
                    ease: 'none',
                });
            });
        } else {
            this.buttons.forEach((button) => {
                gsap.to(button, {
                    scrollTrigger: {
                        trigger: this.trigger,
                        start: () => `${(button.getBoundingClientRect().top + button.getBoundingClientRect().height) * -1} top`,
                        end: () => `${(button.getBoundingClientRect().top) * -1} top`,
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                    backgroundPositionY: '100%',
                    ease: 'none',
                });
            });
        }
    }


    killTimeline() {
        if (this.timeline) {
            this.timeline.kill();
            this.timeline = null;
        }
    }
}